export default [
  {
    text: 'Cuenta de ahorro',
    value: {
      valorID: '63',
      varCodigo: '1',
      varDescripcion: 'Cuenta de Ahorro',
    },
  },
  {
    text: 'Cuenta corriente',
    value: {
      valorID: '63',
      varCodigo: '2',
      varDescripcion: 'Cuenta Corriente',
    },
  },
]
