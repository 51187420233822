export default [
  {
    FINANCIERA_ID: '0010000000001EF',
    EMPRESA_ID: '001P001002539PJ',
    RUC: '20231269071',
    RAZON_SOCIAL: 'CAJA RURAL DE AHORRO Y CRÉDITO RAÍZ S.A.A',
  },
  {
    FINANCIERA_ID: '1000000000001EF',
    EMPRESA_ID: '100E000000001PJ',
    RUC: '20122476309',
    RAZON_SOCIAL: 'Banco Central de Reserva del Perú',
  },
  {
    FINANCIERA_ID: '1000000000002EF',
    EMPRESA_ID: '100E000000002PJ',
    RUC: '20100030595',
    RAZON_SOCIAL: 'Banco de La Nación',
  },
  {
    FINANCIERA_ID: '1000000000003EF',
    EMPRESA_ID: '100E000000003PJ',
    RUC: '20100047218',
    RAZON_SOCIAL: 'BANCO DE CREDITO DEL PERU',
  },
  {
    FINANCIERA_ID: '1000000000004EF',
    EMPRESA_ID: '100E000000004PJ',
    RUC: '20100053455',
    RAZON_SOCIAL: 'BANCO INTERNACIONAL DEL PERU - INTERBANK',
  },
  {
    FINANCIERA_ID: '1000000000005EF',
    EMPRESA_ID: '100E000000005PJ',
    RUC: '20100043140',
    RAZON_SOCIAL: 'SCOTIABANK PERU SAA',
  },
  {
    FINANCIERA_ID: '1000000000006EF',
    EMPRESA_ID: '100E000000006PJ',
    RUC: '20100130204',
    RAZON_SOCIAL: 'BBVA BANCO CONTINENTAL',
  },
  {
    FINANCIERA_ID: '1000000000007EF',
    EMPRESA_ID: '100E000000007PJ',
    RUC: '20509507199',
    RAZON_SOCIAL: 'Banco Comercio',
  },
  {
    FINANCIERA_ID: '1000000000008EF',
    EMPRESA_ID: '100E000000008PJ',
    RUC: '20100105862',
    RAZON_SOCIAL: 'BANCO PICHINCHA SA',
  },
  {
    FINANCIERA_ID: '1000000000009EF',
    EMPRESA_ID: '100E000000009PJ',
    RUC: '20100116635',
    RAZON_SOCIAL: 'Citibank del Perú SA',
  },
  {
    FINANCIERA_ID: '1000000000010EF',
    EMPRESA_ID: '100E000000010PJ',
    RUC: '20101036813',
    RAZON_SOCIAL: 'BANCO INTERAMERICANO DE FINANZAS',
  },
  {
    FINANCIERA_ID: '1000000000011EF',
    EMPRESA_ID: '100E000000011PJ',
    RUC: '20100116392',
    RAZON_SOCIAL: 'CORPORACION FINANCIERA DE DESARROLLO S.A.',
  },
  {
    FINANCIERA_ID: '1000000000012EF',
    EMPRESA_ID: '100E000000012PJ',
    RUC: '20382036655',
    RAZON_SOCIAL: 'Banco de la Microempresa - MIBANCO',
  },
  {
    FINANCIERA_ID: '1000000000013EF',
    EMPRESA_ID: '100E000000013PJ',
    RUC: '20504565794',
    RAZON_SOCIAL: 'Banco Agropecuario -AGROBANCO',
  },
  {
    FINANCIERA_ID: '1000000000014EF',
    EMPRESA_ID: '100E000000014PJ',
    RUC: '20513074370',
    RAZON_SOCIAL: 'BANCO GNB PERU S.A.',
  },
  {
    FINANCIERA_ID: '1000000000015EF',
    EMPRESA_ID: '100E000000015PJ',
    RUC: '20330401991',
    RAZON_SOCIAL: 'Banco Falabella S.A.',
  },
  {
    FINANCIERA_ID: '1000000000016EF',
    EMPRESA_ID: '100E000000016PJ',
    RUC: '20516711559',
    RAZON_SOCIAL: 'BANCO SANTANDER PERÚ S.A.',
  },
  {
    FINANCIERA_ID: '1000000000017EF',
    EMPRESA_ID: '100E000000017PJ',
    RUC: '20259702411',
    RAZON_SOCIAL: 'BANCO RIPLEY PERU S.A.',
  },
  {
    FINANCIERA_ID: '1000000000019EF',
    EMPRESA_ID: '100E000000019PJ',
    RUC: '20517476405',
    RAZON_SOCIAL: 'Banco Azteca del Perú',
  },
  {
    FINANCIERA_ID: '1000000000020EF',
    EMPRESA_ID: '100E000000020PJ',
    RUC: '20517406610',
    RAZON_SOCIAL: 'Deutsche Bank (Perú) S.A.',
  },
  {
    FINANCIERA_ID: '1000000000022EF',
    EMPRESA_ID: '100E000000022PJ',
    RUC: '20337996834',
    RAZON_SOCIAL: 'Financiera TFC S.A.',
  },
  {
    FINANCIERA_ID: '1000000000023EF',
    EMPRESA_ID: '100E000000023PJ',
    RUC: '20255993225',
    RAZON_SOCIAL: 'Crediscotia',
  },
  {
    FINANCIERA_ID: '1000000000024EF',
    EMPRESA_ID: '100E000000024PJ',
    RUC: '20369155360',
    RAZON_SOCIAL: 'Financiera Créditos Arequipa SA',
  },
  {
    FINANCIERA_ID: '1000000000025EF',
    EMPRESA_ID: '100E000000025PJ',
    RUC: '20401319817',
    RAZON_SOCIAL: 'Financiera Confianza S.A.',
  },
  {
    FINANCIERA_ID: '1000000000026EF',
    EMPRESA_ID: '100E000000026PJ',
    RUC: '20521308321',
    RAZON_SOCIAL: 'Financiera Universal S.A.',
  },
  {
    FINANCIERA_ID: '1000000000027EF',
    EMPRESA_ID: '100E000000027PJ',
    RUC: '20100209641',
    RAZON_SOCIAL: 'Caja Municipal de Ahorro y Crédito de Arequipa',
  },
  {
    FINANCIERA_ID: '1000000000028EF',
    EMPRESA_ID: '100E000000028PJ',
    RUC: '20101091083',
    RAZON_SOCIAL: 'Abaco',
  },
  {
    FINANCIERA_ID: '1000000000029EF',
    EMPRESA_ID: '100E000000029PJ',
    RUC: '20100904072',
    RAZON_SOCIAL: 'Aelucoop',
  },
  {
    FINANCIERA_ID: '1000000000030EF',
    EMPRESA_ID: '100E000000030PJ',
    RUC: '20100874068',
    RAZON_SOCIAL: 'Continental',
  },
  {
    FINANCIERA_ID: '1000000000031EF',
    EMPRESA_ID: '100E000000031PJ',
    RUC: '20137392349',
    RAZON_SOCIAL: 'Asistencia Social Ltda. Coopcreas',
  },
  {
    FINANCIERA_ID: '1000000000032EF',
    EMPRESA_ID: '100E000000032PJ',
    RUC: '20148242942',
    RAZON_SOCIAL: 'Celendin',
  },
  {
    FINANCIERA_ID: '1000000000033EF',
    EMPRESA_ID: '100E000000033PJ',
    RUC: '20142607060',
    RAZON_SOCIAL: 'Centenario',
  },
  {
    FINANCIERA_ID: '1000000000034EF',
    EMPRESA_ID: '100E000000034PJ',
    RUC: '20100452236',
    RAZON_SOCIAL: 'Central SIndical Lima',
  },
  {
    FINANCIERA_ID: '1000000000035EF',
    EMPRESA_ID: '100E000000035PJ',
    RUC: '20103691771',
    RAZON_SOCIAL: 'Chiclayo',
  },
  {
    FINANCIERA_ID: '1000000000036EF',
    EMPRESA_ID: '100E000000036PJ',
    RUC: '20141627555',
    RAZON_SOCIAL: 'Chiquinquira Ltda.',
  },
  {
    FINANCIERA_ID: '1000000000037EF',
    EMPRESA_ID: '100E000000037PJ',
    RUC: '20100196485',
    RAZON_SOCIAL: 'Alto Selva Alegre',
  },
  {
    FINANCIERA_ID: '1000000000038EF',
    EMPRESA_ID: '100E000000038PJ',
    RUC: '20102451768',
    RAZON_SOCIAL: 'Cristo Rey - Negritos',
  },
  {
    FINANCIERA_ID: '1000000000039EF',
    EMPRESA_ID: '100E000000039PJ',
    RUC: '20130060839',
    RAZON_SOCIAL: 'COOPERATIVA DE AHORRO Y CRÉDITO CUAJONE',
  },
  {
    FINANCIERA_ID: '1000000000040EF',
    EMPRESA_ID: '100E000000040PJ',
    RUC: '20129826151',
    RAZON_SOCIAL: 'De los Trabajadores de Volcan Cia Minera',
  },
  {
    FINANCIERA_ID: '1000000000041EF',
    EMPRESA_ID: '100E000000041PJ',
    RUC: '20429057711',
    RAZON_SOCIAL: 'Aopcoop',
  },
  {
    FINANCIERA_ID: '1000000000042EF',
    EMPRESA_ID: '100E000000042PJ',
    RUC: '20101256341',
    RAZON_SOCIAL: 'De los Trabajadores del Sector Salud',
  },
  {
    FINANCIERA_ID: '1000000000043EF',
    EMPRESA_ID: '100E000000043PJ',
    RUC: '20101843719',
    RAZON_SOCIAL: 'El Carmen',
  },
  {
    FINANCIERA_ID: '1000000000044EF',
    EMPRESA_ID: '100E000000044PJ',
    RUC: '20138732836',
    RAZON_SOCIAL: 'El Dorado',
  },
  {
    FINANCIERA_ID: '1000000000045EF',
    EMPRESA_ID: '100E000000045PJ',
    RUC: '20100551137',
    RAZON_SOCIAL: 'El Progreso',
  },
  {
    FINANCIERA_ID: '1000000000046EF',
    EMPRESA_ID: '100E000000046PJ',
    RUC: '20447850991',
    RAZON_SOCIAL: 'El Salvador Ltda 06',
  },
  {
    FINANCIERA_ID: '1000000000047EF',
    EMPRESA_ID: '100E000000047PJ',
    RUC: '20123929404',
    RAZON_SOCIAL: 'El Sol',
  },
  {
    FINANCIERA_ID: '1000000000048EF',
    EMPRESA_ID: '100E000000048PJ',
    RUC: '20147965924',
    RAZON_SOCIAL: 'Emphost',
  },
  {
    FINANCIERA_ID: '1000000000049EF',
    EMPRESA_ID: '100E000000049PJ',
    RUC: '20138678874',
    RAZON_SOCIAL: 'La Fabril',
  },
  {
    FINANCIERA_ID: '1000000000050EF',
    EMPRESA_ID: '100E000000050PJ',
    RUC: '20100386349',
    RAZON_SOCIAL: 'La Portuaria',
  },
  {
    FINANCIERA_ID: '1000000000051EF',
    EMPRESA_ID: '100E000000051PJ',
    RUC: '20100886589',
    RAZON_SOCIAL: 'Marbella de Magdalena',
  },
  {
    FINANCIERA_ID: '1000000000052EF',
    EMPRESA_ID: '100E000000052PJ',
    RUC: '20111065013',
    RAZON_SOCIAL: 'COOPERATIVA DE AHORRO Y CREDITO PACIFICO',
  },
  {
    FINANCIERA_ID: '1000000000053EF',
    EMPRESA_ID: '100E000000053PJ',
    RUC: '20170433581',
    RAZON_SOCIAL: 'Parque Industrial',
  },
  {
    FINANCIERA_ID: '1000000000054EF',
    EMPRESA_ID: '100E000000054PJ',
    RUC: '20100859263',
    RAZON_SOCIAL: 'Parroquia Santa Rosa de Lima',
  },
  {
    FINANCIERA_ID: '1000000000055EF',
    EMPRESA_ID: '100E000000055PJ',
    RUC: '20148332771',
    RAZON_SOCIAL: 'Petroperú Ltda',
  },
  {
    FINANCIERA_ID: '1000000000056EF',
    EMPRESA_ID: '100E000000056PJ',
    RUC: '20153334308',
    RAZON_SOCIAL: 'San Hilarión',
  },
  {
    FINANCIERA_ID: '1000000000057EF',
    EMPRESA_ID: '100E000000057PJ',
    RUC: '20118751656',
    RAZON_SOCIAL: 'San Isidro',
  },
  {
    FINANCIERA_ID: '1000000000058EF',
    EMPRESA_ID: '100E000000058PJ',
    RUC: '20134794888',
    RAZON_SOCIAL: 'Toquepala',
  },
  {
    FINANCIERA_ID: '1000000000059EF',
    EMPRESA_ID: '100E000000059PJ',
    RUC: '20100692466',
    RAZON_SOCIAL: 'De los Trabajadores Cia Goodyear Ltda',
  },
  {
    FINANCIERA_ID: '1000000000060EF',
    EMPRESA_ID: '100E000000060PJ',
    RUC: '20101270417',
    RAZON_SOCIAL: 'Trabajadores del Banco de Crédito',
  },
  {
    FINANCIERA_ID: '1000000000061EF',
    EMPRESA_ID: '100E000000061PJ',
    RUC: '20100607965',
    RAZON_SOCIAL: 'Finantel Ltda.',
  },
  {
    FINANCIERA_ID: '1000000000063EF',
    EMPRESA_ID: '100E000000063PJ',
    RUC: '20103534675',
    RAZON_SOCIAL: 'Tuman',
  },
  {
    FINANCIERA_ID: '1000000000064EF',
    EMPRESA_ID: '100E000000064PJ',
    RUC: '20136284593',
    RAZON_SOCIAL: 'Ilo Ltda.',
  },
  {
    FINANCIERA_ID: '1000000000065EF',
    EMPRESA_ID: '100E000000065PJ',
    RUC: '20118475870',
    RAZON_SOCIAL: 'Sgrdo Crzón de Jesús Mercado Lobaton',
  },
  {
    FINANCIERA_ID: '1000000000066EF',
    EMPRESA_ID: '100E000000066PJ',
    RUC: '20217028893',
    RAZON_SOCIAL: 'De los Servidores del Ministerio de Interior',
  },
  {
    FINANCIERA_ID: '1000000000067EF',
    EMPRESA_ID: '100E000000067PJ',
    RUC: '20215887929',
    RAZON_SOCIAL: 'Trabajadores del Bco Central de Reserva',
  },
  {
    FINANCIERA_ID: '1000000000068EF',
    EMPRESA_ID: '100E000000068PJ',
    RUC: '20138163521',
    RAZON_SOCIAL: 'Bata Perú',
  },
  {
    FINANCIERA_ID: '1000000000069EF',
    EMPRESA_ID: '100E000000069PJ',
    RUC: '20375973091',
    RAZON_SOCIAL: 'El Progreso del Comerciante de Carabayllo',
  },
  {
    FINANCIERA_ID: '1000000000070EF',
    EMPRESA_ID: '100E000000070PJ',
    RUC: '20335458193',
    RAZON_SOCIAL: 'San Viator',
  },
  {
    FINANCIERA_ID: '1000000000071EF',
    EMPRESA_ID: '100E000000071PJ',
    RUC: '20123666551',
    RAZON_SOCIAL: 'Crl Francisco Bolognesi',
  },
  {
    FINANCIERA_ID: '1000000000072EF',
    EMPRESA_ID: '100E000000072PJ',
    RUC: '20144895411',
    RAZON_SOCIAL: 'Electrocoop',
  },
  {
    FINANCIERA_ID: '1000000000073EF',
    EMPRESA_ID: '100E000000073PJ',
    RUC: '20117514821',
    RAZON_SOCIAL: 'Del Sector Industria',
  },
  {
    FINANCIERA_ID: '1000000000074EF',
    EMPRESA_ID: '100E000000074PJ',
    RUC: '20207056121',
    RAZON_SOCIAL: 'Del Sector Salud de Ayacucho',
  },
  {
    FINANCIERA_ID: '1000000000075EF',
    EMPRESA_ID: '100E000000075PJ',
    RUC: '20526918429',
    RAZON_SOCIAL: 'Los Andes Cotarusi Aymaraes',
  },
  {
    FINANCIERA_ID: '1000000000076EF',
    EMPRESA_ID: '100E000000076PJ',
    RUC: '20157647912',
    RAZON_SOCIAL: 'Del Mercado Central de Chorrillos',
  },
  {
    FINANCIERA_ID: '1000000000077EF',
    EMPRESA_ID: '100E000000077PJ',
    RUC: '20439025124',
    RAZON_SOCIAL: 'De Cesantes, Jubilados y Activos Sector Salud  CEJUASSA',
  },
  {
    FINANCIERA_ID: '1000000000078EF',
    EMPRESA_ID: '100E000000078PJ',
    RUC: '20501715311',
    RAZON_SOCIAL: 'Paz y Bien',
  },
  {
    FINANCIERA_ID: '1000000000079EF',
    EMPRESA_ID: '100E000000079PJ',
    RUC: '20498280553',
    RAZON_SOCIAL: 'Del Sur',
  },
  {
    FINANCIERA_ID: '1000000000080EF',
    EMPRESA_ID: '100E000000080PJ',
    RUC: '20505248687',
    RAZON_SOCIAL: 'Dios Proveedor',
  },
  {
    FINANCIERA_ID: '1000000000081EF',
    EMPRESA_ID: '100E000000081PJ',
    RUC: '20453715737',
    RAZON_SOCIAL: 'Rondas y Desarrollo RONDESA',
  },
  {
    FINANCIERA_ID: '1000000000082EF',
    EMPRESA_ID: '100E000000082PJ',
    RUC: '20508208074',
    RAZON_SOCIAL: 'COOPERATIVA DE AHORRO Y CREDITO EFIDE',
  },
  {
    FINANCIERA_ID: '1000000000083EF',
    EMPRESA_ID: '100E000000083PJ',
    RUC: '20486065398',
    RAZON_SOCIAL: 'La Florida',
  },
  {
    FINANCIERA_ID: '1000000000084EF',
    EMPRESA_ID: '100E000000084PJ',
    RUC: '20484154741',
    RAZON_SOCIAL: 'Crediahorro Talara',
  },
  {
    FINANCIERA_ID: '1000000000085EF',
    EMPRESA_ID: '100E000000085PJ',
    RUC: '20527533687',
    RAZON_SOCIAL: 'Señor de los Temblores',
  },
  {
    FINANCIERA_ID: '1000000000086EF',
    EMPRESA_ID: '100E000000086PJ',
    RUC: '20486708817',
    RAZON_SOCIAL: 'Del Comerciante de Huancayo',
  },
  {
    FINANCIERA_ID: '1000000000087EF',
    EMPRESA_ID: '100E000000087PJ',
    RUC: '20480014678',
    RAZON_SOCIAL: 'Norandino',
  },
  {
    FINANCIERA_ID: '1000000000088EF',
    EMPRESA_ID: '100E000000088PJ',
    RUC: '20452815827',
    RAZON_SOCIAL: 'Fortaleza de Ayacucho',
  },
  {
    FINANCIERA_ID: '1000000000089EF',
    EMPRESA_ID: '100E000000089PJ',
    RUC: '20518452852',
    RAZON_SOCIAL: 'COOP. PARA EMPRESAS EXPORTADORAS COOPEX',
  },
  {
    FINANCIERA_ID: '1000000000090EF',
    EMPRESA_ID: '100E000000090PJ',
    RUC: '20521248832',
    RAZON_SOCIAL: 'Ciderural',
  },
  {
    FINANCIERA_ID: '1000000000091EF',
    EMPRESA_ID: '100E000000091PJ',
    RUC: '20377892918',
    RAZON_SOCIAL: 'LEASING TOTAL S.A',
  },
  {
    FINANCIERA_ID: '0010000000004EF',
    EMPRESA_ID: '0010000021621PJ',
    RUC: '20113604248',
    RAZON_SOCIAL: 'CAJA MUNICIPAL DE AHORRO Y CREDITO PIURA S.A.C.',
  },
]
