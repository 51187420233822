export default [
  {
    text: 'Soles',
    value: {
      valorID: '1',
      varCodigo: '1',
      varDescripcion: 'Soles',
    },
  },
  {
    text: 'Dólares',
    value: {
      valorID: '1',
      varCodigo: '2',
      varDescripcion: 'Dólares',
    },
  },
  {
    text: 'Euros',
    value: {
      valorID: '1',
      varCodigo: '2',
      varDescripcion: 'Euros',
    },
  },
]
